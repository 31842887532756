import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Shared
import CustomLink from 'components/shared/CustomLink'

const PrekenItem = styled.div`
  position: relative;
`

const Item = styled.div`
  position: relative;
`

const Title = styled.h4`
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.l};
`

const Time = styled.p``

const PrekenArchive = () => {
  const { preken } = useStaticQuery(graphql`
    {
      preken:  allWordpressWpPrekenarchief(sort: {fields: date, order: DESC}) {
        edges {
          node {
            wordpress_id
            title
            path
            acf {
              sermon {
                time
                predecessor
              }
            }
          }
        }
      }
    }
  `)

  return (
    <PrekenItem className="mb-4">
      {preken.edges.map(({ node: { title, path, acf } }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Item key={index} className="mb-3">
          <Title>
            <CustomLink to={path}>{title}</CustomLink>
          </Title>
          {acf.sermon.map(({ time, predecessor }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Time key={index} className="mb-0 pl-3">
              <span>{time}</span>:
              {' '}
              <span>{predecessor}</span>
            </Time>
          ))}
        </Item>
      ))}
    </PrekenItem>
  )
}

export default PrekenArchive